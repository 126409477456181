import ajaxService, { AjaxError } from "AjaxService";
import { AsyncDictionary } from "AsyncDictionary";
import type { Entity } from "breeze-client";
import captionService, { type ResourceInfo } from "CaptionService";
import { FormFlowUsage } from "Constants";
import type { ActionSettings } from "EntityInfoActionMenuItemsProvider";
import { FormFlowErrors, FormFlowSerializationError } from "Errors";
import type { FormFlowAlias } from "FormFlowTypes";
import global from "Global";
import RuleExpressionCondition from "RuleExpressionCondition";

export type FormFlowInfo = {
  PK: string;
  Caption: ResourceInfo;
  ConfigurationKey: string;
  IconFontCode: string;
  Usage?: FormFlowUsage;
  EntityType?: string;
  HeaderEntityType?: string;
  HeaderCollectionName?: string;
  DisplaySequence: number;
  CanRunInParentSession: boolean;
  EntityVisibilityCondition?: string;
  EntityInfoVisibilityCondition?: string;
  IsRestricted: boolean;
  MultiSelectOnly: boolean;
  Alias?: FormFlowAlias;
};

type ErrorResponse = {
  ExceptionType: string;
  Message: string;
};

const cache = new AsyncDictionary<FormFlowInfo[]>();

export function clear(): void {
  cache.clear();
}

/**
 * Gets a list of FormFlowInfos for an entity type
 *
 * @param entityTypeName The entity type name
 * @param headerEntityTypeName Optional property header entity name.
 * @param headerCollectionName Optional property collection property name.
 * @returns A promise of the list of FormFlowInfos
 */
export async function getFormFlowInfosAsync(
  entityTypeName: string,
  headerEntityTypeName = "",
  headerCollectionName = ""
): Promise<FormFlowInfo[]> {
  const result = await cache.getOrAddAsync(`${entityTypeName}|${headerEntityTypeName}|${headerCollectionName}`, () =>
    getCoreAsync(entityTypeName, headerEntityTypeName, headerCollectionName)
  );
  return result;
}

/**
 * Get a list of FormFlowInfos for an entity type for a specific usage
 *
 * @param entityTypeName The entity type name
 * @param usage Form flow usage e.g. maintain multiple
 * @returns A promise of the list of FormFlowInfos
 */
export async function getFormFlowInfosForUsageAsync(entityTypeName: string, usage: string): Promise<FormFlowInfo[]> {
  if (usage !== FormFlowUsage.Maintain && usage !== FormFlowUsage.MaintainMultiple && usage !== FormFlowUsage.New) {
    throw new Error(
      `Unexpected usage '${usage}'. Expected values are '${[
        FormFlowUsage.Maintain,
        FormFlowUsage.MaintainMultiple,
        FormFlowUsage.New,
      ].join(",")}'`
    );
  }
  const infos = await getFormFlowInfosAsync(entityTypeName);
  return infos.filter((info: FormFlowInfo) => {
    return info.Usage === usage;
  });
}

/**
 * Get a list of FormFlowInfos for an entity with header entity/collection specified
 *
 * @param entityTypeName The entity type name
 * @param headerEntityTypeName The header entity name
 * @param headerCollectionName The header collection name
 * @returns A promise of the list of FormFlowInfos
 */
export async function getFormFlowInfosForHeaderAsync(
  entityTypeName: string,
  headerEntityTypeName: string,
  headerCollectionName: string
): Promise<FormFlowInfo[]> {
  const result = await getFormFlowInfosAsync(entityTypeName, headerEntityTypeName, headerCollectionName);
  return result;
}

/**
 * Get a list of FormFlowInfos for an entity with header entity/collection specified
 *
 * @param entityTypeName The entity type name
 * @param headerEntityTypeName The header entity name.
 * @param headerCollectionName The header collection  name.
 * @param usage The formflow usage
 * @returns A promise of the list of FormFlowInfos
 */
export async function getFormFlowInfosForHeaderWithUsageAsync(
  entityTypeName: string,
  headerEntityTypeName: string,
  headerCollectionName: string,
  usage: string
): Promise<FormFlowInfo[]> {
  if (
    usage !== FormFlowUsage.NewWithHeader &&
    usage !== FormFlowUsage.MaintainWithHeader &&
    usage !== FormFlowUsage.MaintainMultipleWithHeader
  ) {
    throw new Error(
      `Unexpected usage '${usage}'. Expected values are '${[
        FormFlowUsage.NewWithHeader,
        FormFlowUsage.MaintainWithHeader,
        FormFlowUsage.MaintainMultipleWithHeader,
      ].join(",")}'`
    );
  }
  const results = await getFormFlowInfosForHeaderAsync(entityTypeName, headerEntityTypeName, headerCollectionName);
  return results.filter((info: FormFlowInfo) => {
    return info.Usage === usage;
  });
}

export function getAvailableFormFlowsPredicate(
  actionSettings: ActionSettings,
  hasMultipleSelections: boolean
): (info: FormFlowInfo) => boolean {
  const rowActionMenuItems = actionSettings?.actionMenuItems?.formFlows;

  if (rowActionMenuItems) {
    return (info: FormFlowInfo) => {
      const isMultipleFormFlowAllowed = info.MultiSelectOnly ? hasMultipleSelections : true;
      return isMultipleFormFlowAllowed && rowActionMenuItems?.some((actionItem) => actionItem.PK === info.PK);
    };
  } else {
    return (info: FormFlowInfo) => {
      const isMultipleFormFlowAllowed = info.MultiSelectOnly ? hasMultipleSelections : true;
      return isFormFlowAccessible(info) && isMultipleFormFlowAllowed;
    };
  }
}

/**
 * Determines if a formflowinfo is restricted or not
 *
 * @param formFlowInfo The FormFlowInfo to check if restricted
 * @returns True or false
 */
export function isFormFlowAccessible(formFlowInfo: FormFlowInfo): boolean {
  return !formFlowInfo.IsRestricted;
}

/**
 * Determines if a FormFlow is visibile on an entity
 *
 * @param entity The entity
 * @param formFlowInfo The FormFlowInfo to check if visibile
 * @returns True or false
 */
export async function isFormFlowVisibleAsync(entity: Entity, formFlowInfo: FormFlowInfo): Promise<boolean> {
  let result = true;
  if (formFlowInfo.EntityVisibilityCondition) {
    const condition = new RuleExpressionCondition(formFlowInfo.EntityVisibilityCondition);
    result = !!(await condition.evaluateAsync(entity));
  }
  return result;
}

/**
 * Determines if a FormFlow is visibile for all entities
 *
 * @param entities The entities
 * @param formFlowInfo The FormFlowInfo to check if visibile
 * @returns True if formFlow is visible for all entites, otherwise it's false
 */
export async function isFormFlowVisibleForAllEntitiesAsync(entities: Entity[], formFlowInfo: FormFlowInfo): Promise<boolean> {
  const isVisiblePerEntity = await Promise.all(
    entities.map(async (entity) => await isFormFlowVisibleAsync(entity, formFlowInfo))
  );
  return isVisiblePerEntity.every((isVisible) => isVisible);
}

async function getCoreAsync(
  entityTypeName: string,
  headerEntityTypeName: string,
  headerCollectionName: string
): Promise<FormFlowInfo[]> {
  const url = `${global.serviceUri}api/bpm/formFlow/getInfos`;
  try {
    const infos = await ajaxService.getAsync<FormFlowInfo[]>(url, {
      entityTypeName,
      moduleCode: global.moduleName,
      headerEntityTypeName,
      headerCollectionName,
    });

    return (infos || []).sort((info1, info2) => {
      if (info1.DisplaySequence < info2.DisplaySequence) {
        return -1;
      }

      if (info1.DisplaySequence > info2.DisplaySequence) {
        return 1;
      }

      return captionService.getStringFromInfo(info1.Caption).toUpperCase() <
        captionService.getStringFromInfo(info2.Caption).toUpperCase()
        ? -1
        : 1;
    });
  } catch (error) {
    if (error instanceof AjaxError) {
      handleGetInfoError(error);
    }
    throw error;
  }
}

function handleGetInfoError(error: AjaxError): void {
  if (error.status === 400) {
    const errorResponse = error.getErrorResponse() as ErrorResponse;
    if (hasFormFlowInfoError(errorResponse)) {
      let errorMessage: string;
      if (global.isPreviewMode()) {
        errorMessage = captionService.getString(
          "eacf27fd-6192-4fc6-a8c7-f012c962ac44",
          "An unexpected error occurred while serializing the form-flow infos. This may be due to invalid configuration.\r\n{0}",
          errorResponse.Message
        );
      } else {
        errorMessage = captionService.getString(
          "9946410c-1208-480c-a12f-1f9d708dfca3",
          "An unexpected error occurred while serializing the form-flow infos. The error has been automatically reported."
        );
      }
      throw new FormFlowSerializationError(errorMessage);
    }
  }
  throw error;
}

function hasFormFlowInfoError(errorResponse: ErrorResponse): boolean {
  if (errorResponse) {
    return errorResponse.ExceptionType === FormFlowErrors.SerializationError;
  }
  return false;
}

/** @deprecated Use named exports instead */
export default {
  clear,
  getAsync: getFormFlowInfosAsync,
  getForUsageAsync: getFormFlowInfosForUsageAsync,
  getForHeaderAsync: getFormFlowInfosForHeaderAsync,
  getForHeaderWithUsageAsync: getFormFlowInfosForHeaderWithUsageAsync,
  getAvailableFormFlowsPredicate,
  isFormFlowAccessible,
  isFormFlowVisibleAsync,
  isFormFlowVisibleForAllEntitiesAsync,
};
